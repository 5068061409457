/* You can add global styles to this file, and also import other style files */
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import '../node_modules/angular-archwizard/styles/archwizard.css';

.required-field {
    color: red;
}
.input-error {
    .ng-select.ng-invalid .ng-select-container,
    .form-control.ng-invalid,
    input.ng-invalid img{
        // #f7b2cb
        // border-color: #f50505 !important;
        border-color: #fc0303 !important;
        border-radius: 2px solid;
        -webkit-transition: border-color 500ms ease-out;
        -moz-transition: border-color 500ms ease-out;
        -o-transition: border-color 500ms ease-out;
        transition: border-color 500ms ease-out;
    }
}